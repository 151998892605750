import styled, { css, keyframes } from "styled-components";

const slideInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const breakpoint = "768px";

export const PhotoGalleryContainer = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;

  @media screen and (max-width: ${breakpoint}) {
    height: auto;
  }
`;

export const GalleryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 1s ease-in-out, background-color 1s ease-in-out;
  opacity: ${({ fade }) => (fade ? 1 : 0)};
  background-color: ${({ fade }) => (fade ? "transparent" : "black")};
`;

export const GalleryButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px 16px;
  font-size: 18px;
  border-radius: 10%;
  opacity: 0.3;
  transition: opacity 0.3s, background-color 0.3s;
  z-index: 100;

  &:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.85);
  }
`;

export const LeftButton = styled(GalleryButton)`
  left: 10px;
`;

export const RightButton = styled(GalleryButton)`
  right: 10px;
`;

export const FAQsCardContainer = styled.div`
  min-height: 53px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #000080;
  background: #fff;
  padding: 0 16px;
  transition: border-color 0.3s, box-shadow 0.3s, background-color 0.3s;
  &:hover,
  &:focus {
    background:  #f0c381;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-color: #f8d7da;
  }

  ${({ isExpanded }) =>
    isExpanded &&
    `
    background:  #f0c381;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-color: #f8d7da;
  `}

  ${({ animate }) =>
    animate &&
    css`
      animation: ${slideInUp} 1s ease-out forwards;
    `}
`;

export const Btn = styled.button`
  display: contents;
  cursor: pointer;
`;

export const FAQsQuestionText = styled.div`
  color: #000080;
  // font-family: "Noto Sans";
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  // flex-grow: 1;
`;

export const FAQsAnswerText = styled.div`
  color: #333;
  // font-family: "Noto Sans";
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  padding: 0px 0px 15px 0px;
  text-align: left;
`;

export const ToggleIcon = styled.div`
  cursor: pointer;
`;

// export const SideCardContainer = styled.div`
//   width: 100%;
//   min-height: 600px;
//   border-radius: 5px;
//   background: var(--White-Theme-Gray---0, #fff);
//   display: flex;
//   flex-direction: column;
//   gap: 20px;
//   padding: 3rem calc((100vw - 1200px) / 2);
//   @media (max-width: 1220px) {
//     padding: 2rem calc((100vw - 1000px) / 2);
//   }
//   @media (max-width: 1000px) {
//     padding: 2rem calc((100vw - 800px) / 2);
//   }
//   @media (max-width: 600px) {
//     padding: 1.5rem 20px;
//   }
// `;

export const SideCardContainer = styled.div`
  width: 100%;
  min-height: 600px;
  border-radius: 5px;
  background: var(--White-Theme-Gray---0, #fff);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 3rem 20%;

  @media (max-width: 1200px) {
    padding: 2rem 14%;
  }

  // @media (max-width: 1000px) {
  //   padding: 2rem 10%;
  // }

  @media (max-width: 600px) {
    padding: 1.5rem 3%;
  }
`;

export const FAQsHeading = styled.div`
  color: #000080;
  text-align: center;
  // font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 100% */
  display: flex;
  self-align: flex-start;
`;

export const TabsTextDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
  @media (max-width: 700px) {
    gap: 30px;
  }
`;

export const Tab1 = styled.div`
  width: max-content;
  height: 30px;
  color: #999;
  text-align: center;
  // font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
  // border-bottom: 2px solid transparent;
  border-bottom: 2px solid #6b41cb;
  &:hover,
  &:focus {
    border-bottom: 2px solid #6b41cb;
    color: var(--Brand-Purple, #996cfe);
  }
  @media (max-width: 700px) {
    width: 90%;
    font-size: 14px;
  }
`;
