import Law2 from "../images/slideshow/law-1.png";
import Law3 from "../images/slideshow/law-2.png";
import Law1 from "../images/slideshow/law-3.png";
import paralegals from "../images/services/Paralegals.png";
import legalAssistantsClientService from "../images/services/Legal_Assistants_Client_Service.png";
import dataEntryAssistant from "../images/services/Data_Entry_Assistant.png";
import bookkeepingAssistanceAccounting from "../images/services/Bookkeeping_Assistance_Accounting.png";
import lawFirmWebsite from "../images/services/Law_Firm_Website_Assistance.png";
import lawFirmDigital from "../images/services/Law-Firm-Digital-Marketing.png";

import {
  GavelOutlined,
  AssignmentIndOutlined,
  KeyboardOutlined,
  AccountBalanceOutlined,
  WebOutlined,
  CampaignOutlined,
} from "@mui/icons-material";
import { Image } from "./style";

export const photoGalleryImages = [Law1, Law2, Law3];

const iconColor = '#CD7F32';
const activeColor = '#000080';

export const iconsData = [
  {
    icon: <GavelOutlined fontSize="large" />,
    id: "Paralegals",
    label: "Paralegals",
    style: (isActive) => ({ color: isActive ? activeColor : iconColor }),
  },
  {
    icon: <AssignmentIndOutlined fontSize="large" />,
    id: "Legal_Assistants",
    label: "Legal Assistants / Client Service",
    style: (isActive) => ({ color: isActive ? activeColor : iconColor }),
  },
  {
    icon: <KeyboardOutlined fontSize="large" />,
    id: "Data_Entry",
    label: "Data Entry Assistant",
    style: (isActive) => ({ color: isActive ? activeColor : iconColor }),
  },
  {
    icon: <AccountBalanceOutlined fontSize="large" />,
    id: "Bookkeeping_Assistance",
    label: "Bookkeeping Assistance / Accounting",
    style: (isActive) => ({ color: isActive ? activeColor : iconColor }),
  },
  {
    icon: <WebOutlined fontSize="large" />,
    id: "Website_Assistance",
    label: "Law Firm Website Assistance",
    style: (isActive) => ({ color: isActive ? activeColor : iconColor }),
  },
  {
    icon: <CampaignOutlined fontSize="large" />,
    id: "Digital_Marketing",
    label: "Law Firm Digital Marketing",
    style: (isActive) => ({ color: isActive ? activeColor : iconColor }),
  },
];


export const content = [
  {
    image: <Image src={paralegals} />,
    label: "Paralegals",
    sublabel: "(Expert paralegals for seamless legal support)",
    content:
      "Our paralegals can support your legal team by conducting research, preparing documents, and ensuring compliance with legal procedures. We can assist in managing case files, gathering evidence, and drafting motions. Our expertise enables your attorneys to concentrate on client representation and court appearances.",
  },
  {
    image: <Image src={legalAssistantsClientService} />,
    label: "Legal Assistants/Client Service",
    sublabel: "(Dedicated legal assistants for exceptional client care)",
    content:
      "Our legal assistants handle administrative tasks and directly support attorneys, making your firm run more smoothly. Let us coordinate client communications, schedule appointments, and manage documents for you. Our proactive approach ensures clients get timely updates and personalized service.",
  },
  {
    image: <Image src={dataEntryAssistant} />,
    label: "Data Entry Assistant",
    sublabel: "(Accurate data entry for organized legal records)",
    content:
      "Our data entry assistants keep your legal records accurate and up-to-date. We enter large amounts of data precisely, which helps with case preparation and client management. This reduces errors and makes your data easy to access.",
  },
  {
    image: <Image src={bookkeepingAssistanceAccounting} />,
    label: "Bookkeeping Assistance/Accounting",
    sublabel: "(Trustworthy bookkeeping for clear finances)",
    content:
      "Our bookkeeping and accounting services keep your firm’s financial records in order. We handle invoicing, payroll, expense tracking, and financial reporting. This gives you a clear view of your firm’s finances and ensures you meet regulatory requirements.",
  },
  {
    image: <Image src={lawFirmWebsite} />,
    label: "Law Firm Website Assistance",
    sublabel: "(Professional website management help for law firms)",
    content:
      "Our website assistance service helps create and maintain your law firm’s online presence. We ensure your website is easy to use, updated, and optimized for search engines. This helps attract new clients and improves your firm’s visibility.",
  },
  {
    image: <Image src={lawFirmDigital} />,
    label: "Law Firm Digital Marketing",
    sublabel: "(Smart digital marketing for legal growth)",
    content:
      "Our digital marketing services boost your law firm’s online reach and engagement. We handle SEO, social media, content marketing, and online ads. These efforts enhance your firm's reputation, attract new clients, and drive growth",
  },
];
