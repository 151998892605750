import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { HashLink } from "react-router-hash-link";

const breakpoint = "768px";

export const Nav = styled.nav`
  background: #fff;
  height: 80px;

  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 0.5rem calc((100vw - 1300px) / 2);
  padding: 0.5rem 9%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const NavLink = styled(Link)`
  color: #000;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  z-index: 101;

  &:first-child {
    border-left: none;
  }

  &.active {
    color: #000080;
    /* color: #cd7f32; */
  }

  &:hover {
    color: #000080;
    opacity: 0.7;
  }

  @media screen and (max-width: ${breakpoint}) {
    padding: 0.4rem 0;
    width: 100%;
    text-align: center;
    border-left: none;
    border-right: none;
    margin-left: 15px;

    &.logo {
      width: 50%;
      padding: 0px 0px;
    }
  }
`;

export const Header = styled.div`
  font-size: x-large;
  font-weight: bold;
`;

export const NavLink2 = styled(HashLink)`
  color: #000;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  z-index: 101;

  &:first-child {
    border-left: none;
  }

  &.active {
    color: #cd7f32;
  }

  &:hover {
    color: #000080;
    opacity: 0.7;
  }

  @media screen and (max-width: ${breakpoint}) {
    padding: 0.4rem 0;
    width: 100%;
    text-align: center;
    border-left: none;
    border-right: none;
    margin-left: 15px;
  }
`;

export const NavLinkBtn = styled.button`
  display: none;
  z-index: 101;

  @media screen and (max-width: ${breakpoint}) {
    display: flex;
    margin-top: 12px;
    padding: 10px 0;
    width: 100%;
    height: 100%;
    border: none;
    background-color: #000080;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
      transition: all 0.2s ease-in-out;
      background: #000;
      color: #fff;
    }
  }
`;

export const Bars = styled.div`
  display: none;
  color: #000;
  transition: transform 0.3s ease-in-out;
  z-index: 101;
  @media screen and (max-width: ${breakpoint}) {
    display: block;
    position: absolute;
    margin-left: 20px;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const Iconbg = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  z-index: 1000;
  background: blue;
  /* background-color: ${({ isOpen }) => (isOpen ? "black" : "transparent")}; */
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  z-index: 101;

  @media screen and (max-width: ${breakpoint}) {
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80px;
    left: 0;
    background: #fff;
    transition: max-height 0.3s ease-in-out;
    max-height: ${({ isOpen }) => (isOpen ? "300px" : "0")};
    overflow: hidden;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media screen and (max-width: ${breakpoint}) {
    display: none;
  }
`;

export const NavBtnLink = styled(HashLink)`
  border-radius: 4px;
  background: #000080;
  padding: 10px 22px;
  color: #fff;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #000;
    color: #fff;
  }
`;

export const NavBtnContent = styled(HashLink)`
  color: #fff;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  padding: 01px;
  margin-left: 10px;
  font-size: 18px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #000;
    color: #fff;
  }
`;

export const LogoImg = styled.img`
  width: 70px;
  @media screen and (max-width: ${breakpoint}) {
    width: 60px;
  }
  @media screen and (max-width: 500px) {
    width: 50px;
  }
  &:hover {
    transition: all 0.2s ease-in-out;
  }
`;
