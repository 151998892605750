import React from "react";

import {
  ContentContainer,
  Content,
  ContentInfo,
  ServicesSubHeading,
  ServicesHeading,
  BtnContainer,
  NavBtn,
  NavBtnLink,
  AboutContainer,
} from "./style";

const AboutUsHome = () => {
  return (
    <AboutContainer>
      <ServicesHeading>Welcome to Simplify LegalOps</ServicesHeading>
      <ServicesSubHeading>
        Your Needs and Our Legal Solutions = Remarkable Results
      </ServicesSubHeading>

      <ContentContainer container spacing={2}>
        <ContentContainer item xs={12} md={10}>
          <Content>
            Welcome to Simplify LegalOps, where we're not just changing the game
            in legal administrative support services, we're rewriting the rules.
            Our mission? To slash your overhead costs without sacrificing an
            ounce of quality. How do we do it? By harnessing the power of
            offshore resources while maintaining a U.S. base of operations.
          </Content>
        </ContentContainer>
      </ContentContainer>
      <BtnContainer container spacing={2}>
        <ContentInfo item xs={6} md={2}> 
          <NavBtn>
            <NavBtnLink bg={"#CD7F32"} smooth to="/about-us">
              Learn More
            </NavBtnLink>
          </NavBtn>
        </ContentInfo>
        <ContentContainer item xs={6} md={2}>
          <NavBtn>
            <NavBtnLink bg={"#000080"} smooth to="/#contactus">
              Get in touch
            </NavBtnLink>
          </NavBtn>
        </ContentContainer>
      </BtnContainer>
    </AboutContainer>
  );
};

export default AboutUsHome;
