import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link, useNavigate } from 'react-router-dom'; 
import { FooterContainer, FooterLink, FooterHashLink, FooterSection, FooterTitle, FooterWrapper, LegalInfo, LegalBlock, FooterText, ReadMoreLink, FlexDiv, SocialIcon } from './style';
import BG from "../assets/images/25841.jpg"
import FB from "../assets/images/facebook-logo.svg"
import FBM from "../assets/images/facebook-messenger-logo.svg"
import INSTA from "../assets/images/instagram-logo.svg"
import LinkedIn from "../assets/images/linkedin-logo.svg"

const Footer = () => {
    const isTab = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate(); 

    const handleNavigate = (path) => () => {
        navigate(path);
    };
    return (
        <FooterContainer bgImage={BG}>
            <FooterWrapper>
                <FooterSection>
                    <FooterTitle>About Us</FooterTitle>
                    <FooterText>Welcome to Simplify LegalOps, where we're not just changing the game in legal administrative support services, we're rewriting the rules. Our mission? To slash your overhead costs without sacrificing an ounce of quality.</FooterText>
                    <ReadMoreLink to="/about-us">Read More</ReadMoreLink>
                </FooterSection>
                <FooterSection>
                    <FooterTitle>Services</FooterTitle>
                    <FooterHashLink smooth to="/#Paralegals">Paralegals</FooterHashLink>
                    <FooterHashLink smooth to="/#Legal_Assistants">Legal Assistants / Client Service</FooterHashLink>
                    <FooterHashLink smooth to="/#Data_Entry">Data Entry Assistant</FooterHashLink>
                    <FooterHashLink smooth to="/#Bookkeeping_Assistance">Bookkeeping Assistance / Accounting</FooterHashLink>
                    <FooterHashLink smooth to="/#Website_Assistance">Law Firm Website Assistance</FooterHashLink>
                    <FooterHashLink smooth to="/#Digital_Marketing">Law Firm Digital Marketing</FooterHashLink>
                    <ReadMoreLink smooth to="/#services">Learn More</ReadMoreLink>
                </FooterSection>
                <FooterSection>
                    <FooterTitle>Quick Links</FooterTitle>
                    <FooterLink to="/about-us">About Simplify Legal Ops</FooterLink>
                    <FooterHashLink smooth to="/about-us">Why Choose Simplify Legal Ops?</FooterHashLink>
                    <FooterHashLink smooth to="/#faqs">FAQs</FooterHashLink>
                    {/* <FooterLink onClick={handleNavigate('/pricing')}>Our Pricing Model</FooterLink> */}
                </FooterSection>
                <FooterSection>
                    <FooterTitle>Contact Us</FooterTitle>
                    <FooterText>Address: 14210 Edinburgh Ct, Houston,TX, 77077</FooterText>
                    <FooterText>Email: info@simplifylegalops.com</FooterText>
                    <FlexDiv style={{gap: "0.8rem", marginTop: "0.5rem"}}>
                        {/* <SocialIcon src={FB} alt="facebook" />
                        <SocialIcon src={FBM} alt="massenger" />
                        <SocialIcon src={INSTA} alt="Instagram" />
                        // <SocialIcon src={LinkedIn} alt="linked-in" /> */}
                    </FlexDiv>
                </FooterSection>
            </FooterWrapper>
            <hr style={{ height: "1px", background: "#996cfe", width: "100%" }}></hr>
            <LegalInfo>
                {isTab ? (
                    <>
                        <LegalBlock>© 2024 - Simplify Legal Ops</LegalBlock>
                        <LegalBlock>Sitemap | Partner with Us | Privacy Policy</LegalBlock>
                        <LegalBlock>Terms & Conditions</LegalBlock>
                    </>
                ) : (
                    <LegalBlock>© 2024 -  Simplify Legal Ops | Partner with Us | Privacy Policy | Terms & Conditions</LegalBlock>
                )}
            </LegalInfo>
        </FooterContainer>
    );
};

export default Footer;