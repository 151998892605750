import React from "react";
import AboutUsComp from "../components/aboutus/AboutUsComp";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import SidePannel from "../components/home/SidePannel";
import { useEffect } from "react";
const AboutUsPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <AboutUsComp />
      <SidePannel />
      <Footer/>
    </>
  );
};

export default AboutUsPage;
