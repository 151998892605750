import React from "react";
import {
  StyledContainer,
  HeaderContainer,
  Header,
  SubHeader,
  Section,
  SectionHeader,
  StyledButton,
  ImageBox,
  TextContent,
  Feature,
  FeatureHeader,
  FeatureText,
  Text,
} from "./style";
import { useInView } from "react-intersection-observer";
import AboutUsImg1 from "../assets/images/aboutUs/about-us-1.png";
import AboutUsImg2 from "../assets/images/aboutUs/about-us-2.png";
import AboutUsImg3 from "../assets/images/aboutUs/about-us-3.png";
import AboutUsImg4 from "../assets/images/aboutUs/about-us-4.png";


const AboutUs = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.3 });
  const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0.3 });
  const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 0.3 });
  const [ref4, inView4] = useInView({ triggerOnce: true, threshold: 0.3 });
  const [ref5, inView5] = useInView({ triggerOnce: true, threshold: 0.3 });

  return (
    <StyledContainer>
      <HeaderContainer>
        <Header animate={true}>Welcome to Simplify LegalOps</Header>
        <SubHeader animate={true}>
          Not just changing the game in legal administrative support services,
        </SubHeader>
        <SubHeader animate={true}>we're rewriting the rules.</SubHeader>
      </HeaderContainer>

      <Section reverse>
        <ImageBox>
          <img src={AboutUsImg1} alt="About Us" style={{ borderRadius: 5 }} />
        </ImageBox>
        <TextContent>
          <SectionHeader>About-Us</SectionHeader>
          <Text
          //   animate={inView}
          >
            Welcome to Simplify LegalOps, where we're not just changing the game
            in legal administrative support services, we're rewriting the rules.
            Our mission? To slash your overhead costs without sacrificing an
            ounce of quality. How do we do it? By harnessing the power of
            offshore resources while maintaining a U.S. base of operations. When
            you partner with us, you're not just getting affordable
            services—you're getting the same caliber of work delivered by
            seasoned professionals. Whether it's data entry, legal research,
            blog upkeep, or bookkeeping, our dedicated team has you covered.
          </Text>
          <Text
          //   animate={inView}
          >
            But it's not just about saving money. At Simplify LegalOps, we
            understand the pressures that come with running a law firm. That's
            why our ultimate goal is to make your life easier. We want you to
            shine, stress-free. Because when you thrive, we thrive. With
            Simplify LegalOps, it's not just about business—it's about enhancing
            your quality of life. And yes, even the smallest firms can enjoy the
            benefits of our robust legal operations solutions. Welcome to a
            simpler, more efficient way of doing legal business. Welcome to
            Simplify LegalOps.
          </Text>
        </TextContent>
      </Section>

      <Section ref={ref}>
        <ImageBox>
          <img src={AboutUsImg2} alt="Our Mission" style={{ borderRadius: 5 }} />
        </ImageBox>
        <TextContent right>
          <SectionHeader>Our Mission</SectionHeader>
          <Text
          //   animate={inView}
          >
            Our mission at Simplify LegalOps is to revolutionize the way law
            firms manage their operations. From the solo-practitioner trying to
            juggle legal work with administrative necessities to large
            multi-national firms aiming to reduce costs to continue expanding we
            have a solution. We aim to empower legal professionals with
            cost-effective solutions that enhance efficiency, reduce overhead
            costs, and ultimately, drive success. By leveraging offshore
            services, we strive to make high-quality legal support accessible to
            firms of all sizes, enabling them to focus on delivering exceptional
            legal services to their clients.
          </Text>
        </TextContent>
      </Section>

      <Section reverse>
        <ImageBox>
          <img
            src={AboutUsImg3}
            alt="Our Approach"
            style={{ borderRadius: 5 }}
          />
        </ImageBox>
        <TextContent>
          <SectionHeader>Our Approach</SectionHeader>
          <Text
          //   animate={inView}
          >
            At Simplify LegalOps, we take a client-centric approach to
            everything we do. Our team of experienced professionals works
            tirelessly to understand the unique needs and challenges of each law
            firm we serve. We then tailor our services to address those needs,
            providing comprehensive support across various aspects of legal
            operations. From administrative tasks and document management to
            research and writing, to bookkeeping, contract billing, and web
            development, we handle it all so that our clients can devote their
            time and resources to practicing law and serving their clients. Our
            approach is rooted in efficiency, transparency, and a commitment to
            delivering results that exceed expectations.
          </Text>
        </TextContent>
      </Section>
      <Section>
        <ImageBox right>
          <img
            src={AboutUsImg4}
            alt="Why Choose Us?"
            style={{ borderRadius: 5 }}
          />
        </ImageBox>
        <TextContent>
          <SectionHeader>Why Choose Simplify LegalOps?</SectionHeader>
          <Text>
            Choosing Simplify LegalOps means choosing a partner dedicated to
            your firm's success. Here's why law firms trust us to simplify their
            legal support tasks:
          </Text>
          <Feature>
            <FeatureHeader>Cost-Effective Solutions</FeatureHeader>
            <FeatureText ref={ref1} animate={inView1}>
              We understand the importance of cost-effectiveness in today's
              competitive legal landscape. By leveraging offshore resources,
              we're able to offer high-quality services at significantly lower
              costs than traditional solutions, allowing law firms to maximize
              their profitability without compromising on quality. Our employees
              are educated and trained professionals in fields of law, medicine,
              finance, software development, etc.
            </FeatureText>
          </Feature>
          <Feature>
            <FeatureHeader>Quality Assurance</FeatureHeader>
            <FeatureText ref={ref2} animate={inView2}>
              While we prioritize cost-effectiveness, we never compromise on
              quality. Our team undergoes rigorous training and adheres to
              strict quality assurance protocols to ensure that every task is
              completed accurately and efficiently. Attorneys licensed in the
              United States provide training and support. With Simplify
              LegalOps, you can trust that your services are in capable hands.
            </FeatureText>
          </Feature>
          <Feature>
            <FeatureHeader>Scalability</FeatureHeader>
            <FeatureText ref={ref3} animate={inView3}>
              Whether you're a solo practitioner or a large law firm, we have
              the scalability to meet your needs. Our flexible service offerings
              allow you to scale up or down as your firm grows or your workload
              fluctuates, ensuring that you always have the support you need
              when you need it.
            </FeatureText>
          </Feature>
          <Feature>
            <FeatureHeader>Variety of Services</FeatureHeader>
            <FeatureText ref={ref4} animate={inView4}>
              Different firms have different needs. A solo practitioner criminal
              attorney who needs assistance managing the calendar, setting jail
              appointments, billing state and federal contracts? We can help. A
              firm who needs someone to review and organize discovery? We can
              help. An attorney who wants to expand their online presence? We
              can help.
            </FeatureText>
          </Feature>
          <Feature>
            <FeatureHeader>Personalized Support</FeatureHeader>
            <FeatureText ref={ref5} animate={inView5}>
              Your success is our top priority. When you partner with Simplify
              LegalOps, you'll receive personalized support from a dedicated
              team of professionals who are invested in your firm's growth and
              prosperity. We're here to listen, collaborate, and provide expert
              guidance every step of the way. After all, your success means our
              success.
            </FeatureText>
          </Feature>
        </TextContent>
      </Section>

      <StyledButton smooth to="/#contactus">Get Started Today</StyledButton>
    </StyledContainer>
  );
};

export default AboutUs;
