import React, { useState, useEffect } from "react";
import {
  PhotoGalleryContainer,
  GalleryImage,
  LeftButton,
  RightButton,
} from "./style";
import { photoGalleryImages } from "../assets/data/data";

const PhotoGallery = () => {
  const [current, setCurrent] = useState(0);
  const [fade, setFade] = useState(true);
  const interval = 5000;

  useEffect(() => {
    const timer = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrent((current) => (current + 1) % photoGalleryImages.length);
        setFade(true);
      }, 1000);
    }, interval);

    return () => clearInterval(timer);
  }, [photoGalleryImages.length, interval]);

  const nextPhoto = () => {
    setFade(false);
    setTimeout(() => {
      setCurrent((current) => (current + 1) % photoGalleryImages.length);
      setFade(true);
    }, 1000);
  };

  const prevPhoto = () => {
    setFade(false);
    setTimeout(() => {
      setCurrent(
        (current) =>
          (current + photoGalleryImages.length - 1) % photoGalleryImages.length
      );
      setFade(true);
    }, 1000);
  };

  return (
    <PhotoGalleryContainer>
      <LeftButton onClick={prevPhoto}>&#10094;</LeftButton>
      <GalleryImage
        src={photoGalleryImages[current]}
        alt="Gallery"
        fade={fade}
      />
      <RightButton onClick={nextPhoto}>&#10095;</RightButton>
    </PhotoGalleryContainer>
  );
};

export default PhotoGallery;
