import styled from "styled-components";

export const ButtonStyle = styled.div`
position: fixed;
bottom: 90px;
right: 30px;
padding: 8px 12px;
font-size: 20px;
opacity: 90%;
z-index: 9999;
border: none;
border-radius: 8%;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
background: #000080;
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
transition: box-shadow 0.3s ease, transform 0.3s ease, opacity 0.3s ease, background 0.3s ease;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
    background: #CD7F32;
    opacity: 0.8;
  }
`;