export const FAQsData = [
  {
    id: 1,
    question: "What qualifications do your paralegals have?",
    answer:
      "Our paralegals are certified professionals with extensive experience in various areas of law, ensuring they can provide high-quality support for your legal team.",
  },
  {
    id: 2,
    question: "How do your legal assistants improve client service?",
    answer:
      "Our legal assistants improve client service by efficiently managing communications, scheduling, and documentation, ensuring clients receive timely updates and personalized attention.",
  },
  {
    id: 3,
    question:
      "What measures do you take to ensure data accuracy in your data entry services?",
    answer:
      "We employ strict quality control processes and use advanced data management tools to ensure the highest level of accuracy in our data entry services.",
  },
  {
    id: 4,
    question: "How can your bookkeeping services benefit my law firm?",
    answer:
      "Our bookkeeping services ensure accurate financial records, streamline invoicing and payroll processes, and provide detailed financial reports, helping you maintain financial clarity and compliance.",
  },
  {
    id: 5,
    question: "What are the key features of your law firm website assistance?",
    answer:
      "Our website assistance includes website design and maintenance, content updates, SEO optimization, and ensuring the site is user-friendly and secure.",
  },
  {
    id: 6,
    question: "How does your digital marketing service help law firms grow?",
    answer:
      "Our digital marketing service helps law firms grow by increasing their online presence through targeted SEO, social media strategies, content marketing, and online advertising, attracting more clients and enhancing their reputation.",
  },
];
