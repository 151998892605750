import styled from "styled-components";
import { Box, Grid } from "@mui/material";
import { HashLink } from "react-router-hash-link";
const breakpoint = "768px";

export const NavBtnLink = styled(HashLink)`
  border-radius: 4px;
  background: ${({ bg }) => (bg ? bg : "black")};
  padding: 10px 22px;
  color: #fff;
  outline: none;
  cursor: pointer;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
  }
  @media (max-width: 700px) {
  }
  /* transition: all 0.2s ease-in-out; */
  text-decoration: none;

  /* &:hover {
    transition: all 0.2s ease-in-out;
    background: #000;
    color: #fff;
  } */
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;
`;

export const BtnContainer = styled(Grid)`
  align-items: center;
  align-content: center;
  justify-content: center;
  display: flex;
`;

export const ContentInfo = styled(Grid)`
  align-items: right;
  align-content: right;
  justify-content: right;
  align-self: right;
  display: flex;
`;

export const Content = styled.div`
  font-size: large;
  margin-bottom: 20px;
  color: #444444;

  @media screen and (max-width: ${breakpoint}) {
    display: flex;
    font-size: medium;
    justify-content: center;
    text-align: justify;
    text-justify: inter-word;
    padding: 1rem 3%;
  }
`;

export const ContentContainer = styled(Grid)`
  align-items: center;
  align-content: center;
  justify-content: center;
`;

export const ServicesHeading = styled(Box)`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-size: xx-large;
  font-weight: bold;
  margin-bottom: 25px;
  color: #000080;
  @media screen and (max-width: ${breakpoint}) {
    font-size: large;
  }
`;

export const ServicesSubHeading = styled(Box)`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  align-self: center;
  font-size: large;
  margin-bottom: 25px;
  color: #444444;

  @media screen and (max-width: ${breakpoint}) {
    font-size: medium;
    padding: 0px 3%;
  }
`;

export const AboutContainer = styled(Box)`
  margin-bottom: 25px;
  margin-top: 25px;
`;
