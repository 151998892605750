import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import AboutUs from "../pages/AboutUs";
import { ToastContainer } from "react-toastify";
import ScrollToTopButton from "../components/scrollToTopButton/ScrollToTopButton";

const Router = () => {
  return (
    <>
      <ScrollToTopButton />
      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
      </Routes>
      <ToastContainer />
    </>
  );
};

export default Router;
