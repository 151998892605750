import styled, { css, keyframes } from "styled-components";

import { TextField, Box, Grid, CardActions } from "@mui/material";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import LocationOnIcon from '@mui/icons-material/LocationOn';


const breakpoint = "768px";

const slideInRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(100px); 
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeInUp = keyframes`
  from {
    opacity: 0;
   transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const MailIcon = styled(MarkunreadIcon)`
  color: #000080;
  margin-right: 10px;
`;

export const PhoneIcon = styled(LocationOnIcon)`
  color: #000080;
  margin-right: 10px;
  font-size: 2rem; /* Adjust the size as needed */
`;

export const PhoneIcon2 = styled(PermPhoneMsgIcon)`
  opacity: 0;
  margin-right: 10px;
`;

export const Heading = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: #fafafa;
  padding: 30px 0px;
`;

export const Heading1 = styled(Box)`
  align-items: center;
  align-content: center;
  justify-content: center;
  font-size: xx-large;
  font-weight: bold;
  margin-bottom: 0px;
  color: #000080;
`;

export const ContactContainer = styled(Box)`
  display: flex;
  background-color: #fafafa;
  padding: 1rem 15%;
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

  // @media (max-width: 1200px) {
  //   padding: 2rem 8%;
  // }

  @media (max-width: 1000px) {
    padding: 2rem 6%;
  }

  @media (max-width: 600px) {
    padding: 1.5rem 3%;
  }
`;

export const ContactGrid = styled(Grid)``;

export const GridItem = styled(Grid)`
  display: flex;
  ${({ animate }) =>
    animate &&
    css`
      animation: ${slideInRight} 0.6s ease-in-out forwards;
    `}
`;

export const ContactSubHeading = styled(Box)`
  display: flex;
  margin-top: 20px;
  font-size: x-large;

  @media screen and (max-width: ${breakpoint}) {
    font-size: medium;
  }
`;

export const ContactSubHeading3 = styled(Box)`
  display: flex;
  margin-bottom: 40px;
  font-size: large;

  @media screen and (max-width: ${breakpoint}) {
    font-size: medium;
  }
`;

export const ContactSubHeading2 = styled(Box)`
  display: flex;
  font-size: medium;
  margin-top: 30px;

  @media screen and (max-width: ${breakpoint}) {
    font-size: medium;
  }
`;

export const InputField = styled(TextField)`
  margin-bottom: 20px;
  size: large;

  .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #000080;
    }
  }

  .MuiInputLabel-root {
    color: black; // Custom label color
  }

  .MuiInputLabel-root.Mui-focused {
    color: #000080; // Custom label color when focused
  }
`;

export const ContentContainer = styled(Grid)`
  display: flex;
`;

export const InputFieldContainer = styled(Grid)`
  padding-bottom: 15px;
`;

export const PopupBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Space between text and icon */
  border-radius: 5px;
  height: 40px;
  width: 30%; /* Adjust the width as needed */
  padding: 10px 0; /* Adjust padding to ensure the button height is consistent */
  color: #fff;
  font-size: 15px;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background-color: #000080;
  border: none;

  &:hover {
    background-color: black;
    color: #fff;
  }
  &.reset {
    background-color: transparent;
    color: #CD7F32;
    border: 1px solid #CD7F32; /* Use border instead of outline */
  }

  &.reset:hover {
    background-color: transparent;
    color: black;
    border: 1px solid black; /* Use border instead of outline */
  }
`;

// export const ButtonContainer = styled(CardActions)`
//   display: flex;
//   align-items: flex-end;
//   align-content: flex-end;
//   justify-content: flex-end;
//   margin-right: 0.5rem;
//   margin-bottom: 0.5rem;
// `;

export const ButtonContainer = styled(CardActions)`
  display: flex;
  align-items: center;
  align-content: flex-end;
  justify-content: center;
  margin-bottom: 0.5rem;
`;
