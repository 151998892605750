import { Grid, TextField } from '@mui/material';
import styled from 'styled-components';

export const ContentContainer = styled(Grid)`
  display: flex;
`;

export const InputFieldContainer = styled(Grid)`
  padding-bottom: 15px;
`;

export const PopupBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Space between text and icon */
  border-radius: 5px;
  height: 40px;
  width: 35%; /* Adjust the width as needed */
  padding: 10px 0; /* Adjust padding to ensure the button height is consistent */
  color: #fff;
  font-size: 15px;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background-color: #000080;
  border: none;

  &:hover {
    background-color: #1A1AFF;
    color: #fff;
  }
`;

export const PopupContainer = styled.div`
  position: fixed;
  top: 74%;
  z-index: 1001;
  background-color: #fafafa;
  padding: 20px;
  border-radius: 10px;
  transition: right 0.5s ease-in-out;
  transform: translateY(-50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  right: ${({ isPopupVisible }) => (isPopupVisible ? '0' : '-500vw')}; /* Start offscreen */
  max-width: 90vw; /* Ensure popup fits on smaller screens */
  width: 400px; /* Set a default width */

  @media (max-width: 768px) {
    right: ${({ isPopupVisible }) => (isPopupVisible ? '0' : '-100vw')}; /* Adjust for smaller screens */
    width: 80vw; /* Adjust width for smaller screens */
  }

  @media (max-width: 480px) {
    right: ${({ isPopupVisible }) => (isPopupVisible ? '0' : '-100vw')}; /* Adjust for smaller screens */
    width: 90vw; /* Adjust width for smaller screens */
  }
`;

export const InputField = styled(TextField)`
  
  margin-bottom: 20px;
  size: large;

  .MuiOutlinedInput-root {
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #000080; // Color when focused
    }
  }

  .MuiInput-underline:after {
    border-bottom-color: #000080; // Change this to your desired color
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom-color: #000080; // Change this to your desired hover color
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: ${({ isPopupVisible }) => (isPopupVisible ? 'block' : 'none')};
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
`;

export const Heading = styled.h3`
  font-weight: bold;
  margin-bottom: 0px;
`

export const Button = styled.button`
  background-color: #000080;
  color: white;
  border: none;
  padding: 10px 5px;
  transform: rotate(180deg);
  cursor: pointer;
  font-size: 15px;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  display: flex;
  z-index: 1000;
  align-items: center;
  justify-content: center;

  &.actives {
    margin-left: -48px;
      position: absolute;

  }
`;

export const ContactButton = styled.div`
  position: fixed;
  display: flex;
  right: 0; /* Use 0 to ensure it's aligned with the right edge */
  top: 60%; /* Center the button vertically */
  transform: translateY(-50%);
  transition: right 0.5s ease-in-out;
  color: transparent;
  border: none;
  z-index: 1000;

  @media (max-width: 768px) {
    right: 0; /* Ensure the button is fully visible on smaller screens */
  }

  @media (max-width: 480px) {
    right: 0; /* Ensure the button is fully visible on smaller screens */
  }
`;