import styled from 'styled-components';
import { IconButton, Box, Grid } from '@mui/material';

const breakpoint = '768px';

export const CarouselContainer = styled(Box)`
  display: flex;
  align-items: center;
  background-color: #fafafa;
  padding: 10px;
  position: relative;
  overflow: hidden;
  user-select: none; /* Prevents text selection while dragging */
`;

export const NavButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
`;

export const CarouselContent = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
`;

export const CarouselItem = styled.div`
  width: 10%;
  text-align: center;
  padding: 5px;
  border-radius: 10px;
  transition: transform 0.2s ease, background-color 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
  margin: 0px 10px;
  user-select: none;

  &:hover {
    transform: scale(1.05);
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  &.active {
    transform: scale(0.95);
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;

export const ItemLabel = styled.div`
  font-size: small;
`;

export const ServicesContainer = styled(Box)`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: #fafafa;
  padding: 10px;
  position: relative;
  overflow: hidden;
  user-select: none;

  
`;

export const Heading = styled.div`
  font-size: xx-large;
  font-weight: bold;
  margin-bottom: 20px;

  @media screen and (max-width: ${breakpoint}) {
    font-size: x-large;
    display: flex;
    justify-content: center;
  }
`;

export const Content = styled.div`
  font-size: large;
  margin-bottom: 20px;

  @media screen and (max-width: ${breakpoint}) {
    display: flex;
    justify-content: center;
    margin: 0px 20px;
  }
`;

export const Image = styled.img`
  width: 500px;
  height: 400px;
  border-radius: 15px;

  @media screen and (max-width: ${breakpoint}) {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
  }
`;

export const ContentContainer = styled(Grid)`
  align-items: center;
  align-content: center;
  justify-content: center;
`;

export const ContentInfo = styled(Grid)`
  align-items: center;
  align-content: center;
  justify-content: center;
  align-self: center
`;

export const SevicesHeading = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fafafa;
  padding: 40px 0px;
`;

export const SevicesHeading1 = styled(Box)`
  display: flex;
  justify-content: center;
  font-size: xx-large;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const SevicesSubHeading = styled(Box)`
  display: flex;
  justify-content: center;
  font-size: large;
`;

