import React, { useState } from "react";
import { SideCardContainer, FAQsHeading } from "./style";
import { FAQsData } from "./data";
import FAQsCard from "./FAQsCard";

const FAQs = () => {
  const [expandedCard, setExpandedCard] = useState(null);

  const handleToggle = (id) => {
    setExpandedCard(expandedCard === id ? null : id);
  };
  return (
    <>
      <SideCardContainer>
        <FAQsHeading>FAQs</FAQsHeading>
        <hr
          style={{ height: "2px", background: "#000080", width: "100%" }}
        ></hr>

        <>
          {FAQsData.map((item, index) => (
            <FAQsCard
              key={index}
              question={item.question}
              answer={item.answer}
              isExpanded={expandedCard === item.id}
              onToggle={() => handleToggle(item.id)}
            />
          ))}
        </>
      </SideCardContainer>
    </>
  );
};

export default FAQs;
