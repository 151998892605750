import React from "react";
import { IconButton, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  FAQsCardContainer,
  FAQsQuestionText,
  FAQsAnswerText,
  Btn,
} from "./style";
import { useInView } from "react-intersection-observer";
const FAQsCard = ({ question, answer, isExpanded, onToggle }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <FAQsCardContainer isExpanded={isExpanded} ref={ref} animate={inView}>
      <Btn onClick={onToggle}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <FAQsQuestionText>{question}</FAQsQuestionText>
          <IconButton
            style={{
              padding: "15px 0px",
              margin: "0",
              display: "flex",
              alignItems: "center",
            }}
            onClick={onToggle}
          >
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
      </Btn>
      <Collapse in={isExpanded} style={{ width: "100%" }}>
        <FAQsAnswerText>{answer}</FAQsAnswerText>
      </Collapse>
    </FAQsCardContainer>
  );
};

export default FAQsCard;
