import React, { useState } from "react";
import {
  Nav,
  NavLink,
  NavLink2,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
  NavLinkBtn,
  Header,
  NavBtnContent,
  LogoImg,
  Iconbg,
} from "./style";
import { FaBars, FaTimes } from "react-icons/fa";
import Logo from "../assets/images/logoMain.svg";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Nav>
        <NavLink to="/" className="logo">
          <LogoImg src={Logo} alt="Simplify Legal Ops" />
        </NavLink>

        <Bars onClick={toggleMenu}>{isOpen ? <FaTimes /> : <FaBars />}</Bars>

        <NavMenu isOpen={isOpen}>
          <NavLink to="/" activeStyle>
            Home
          </NavLink>
          <NavLink to="/about-us">
            About Us
          </NavLink>
          <NavLink2 smooth to="/#services">
            Services
          </NavLink2>
          {/* <NavLink2 smooth to="/#pricing">
            Pricing
          </NavLink2> */}
          <NavLink2 smooth to="/#faqs">
            FAQS
          </NavLink2>
          <NavLinkBtn>
            <NavBtnContent smooth to="/#contactus">
              Get in touch
            </NavBtnContent>
          </NavLinkBtn>
        </NavMenu>
        <NavBtn>
          <NavBtnLink smooth to="/#contactus">
            Get in touch
          </NavBtnLink>
        </NavBtn>
      </Nav>
    </>
  );
};

export default Navbar;
