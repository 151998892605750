import React, { useState, useRef, useEffect, useCallback } from 'react';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Box, Grid, CardMedia } from '@mui/material';
import { iconsData, content } from "../assets/data/data";
import { useNavigate, useLocation } from 'react-router-dom';

import {
    CarouselContainer,
    CarouselItem,
    ItemLabel,
    ServicesContainer,
    Heading,
    ServicesHeading,
    SubHeading,
    ContentContainer,
    ServicesHeading1,
    CarouselContent,
    Content,
    ContentInfo,
} from './style';

const Services = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeContnet, setActiveContent] = useState(content[0]);
    const contentRef = useRef(null);
    const location = useLocation(); // To access location details
    const navigate = useNavigate(); // This replaces useHistory

    const scrollLeft = () => {
        if (contentRef.current) {
            contentRef.current.scrollBy({ left: -200, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (contentRef.current) {
            contentRef.current.scrollBy({ left: 200, behavior: 'smooth' });
        }
    };

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setActiveIndex((prevIndex) => (prevIndex === iconsData.length - 1 ? 0 : prevIndex + 1) % iconsData.length);
    //     }, 3000);

    //     return () => clearInterval(interval);
    // }, [iconsData.length]);

    // useEffect(() => {
    //     setActiveContent(content[activeIndex]);
    // }, [activeIndex, content]);

    // Function to handle item click, setting active index
    const handleClick = (index) => {
        setActiveIndex(index);
        setActiveContent(content[index])
        const id = iconsData[index].id;
        navigate(`/#${id}`); // Navigate to a new hash
    };

    // Effect to set active index based on URL hash
    useEffect(() => {
        const hash = location.hash.replace('#', ''); // Remove the '#' from hash
        const foundIndex = iconsData.findIndex(item => `#${item.id}` === location.hash);
        if (foundIndex !== -1) {
            setActiveIndex(foundIndex);
            setActiveContent(content[foundIndex])
        }
    }, [location.hash, iconsData]); // Depend on location.hash and iconsData


    return (
        <>
            <ServicesHeading>
                <ServicesHeading1>Legal Support Services We Offer</ServicesHeading1>
            </ServicesHeading>
            <CarouselContainer>
                <CarouselContent>
                    {iconsData.map((item, index) => (
                        <CarouselItem
                            key={index}
                            id={item.id}
                            onClick={() => handleClick(index)}
                            className={activeIndex === index ? 'active' : ''}
                        >
                            <div>{React.cloneElement(item.icon, { style: item.style(activeIndex === index) })}</div>
                            <ItemLabel>{item.label}</ItemLabel>
                        </CarouselItem>
                    ))}
                </CarouselContent>
            </CarouselContainer>
            <ServicesContainer>
                <ContentContainer container spacing={2}>
                    <Grid item xs={12} md={5} >
                        {activeContnet.image}
                    </Grid>
                    <ContentInfo item xs={12} md={6}>
                        <ContentInfo item xs={12}>
                            <Heading>{activeContnet.label}</Heading>
                        </ContentInfo>
                        <ContentInfo item xs={12}>
                            <SubHeading>{activeContnet.sublabel}</SubHeading>
                        </ContentInfo>
                        <ContentInfo item xs={12}>
                            <Content>{activeContnet.content}</Content>
                        </ContentInfo>
                    </ContentInfo>
                </ContentContainer>
            </ServicesContainer>
        </>
    );
};
export default Services;
