import styled from "styled-components";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export const FooterContainer = styled.footer`
  background: ${({ bgImage }) => `url(${bgImage})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: auto;
  color: white;
`;

// export const FooterWrapper = styled.div`
//   padding: 3rem calc((100vw - 1200px) / 2);
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
//   @media (max-width: 1200px) {
//     padding: 2rem calc((100vw - 1000px) / 2);
//     flex-direction: row;
//     justify-content: space-around;
//   }
//   @media (max-width: 1000px) {
//     padding: 2rem calc((100vw - 800px) / 2);
//     flex-direction: row;
//     justify-content: space-around;
//   }
//   @media (max-width: 600px) {
//     padding: 1.5rem 20px;
//     flex-direction: column;
//     align-items: center;
//   }
// `;

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 3rem 10%;
  @media (max-width: 1200px) {
    padding: 2rem 8%;
    flex-direction: row;
    justify-content: space-around;
  }

  @media (max-width: 1000px) {
    padding: 2rem 6%;
    flex-direction: row;
    justify-content: space-around;
  }

  @media (max-width: 600px) {
    padding: 1.5rem 3%;
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 23%;
  position: relative;
  gap: 0.5rem;
  @media (max-width: 1000px) {
    width: 45%;
    margin-bottom: 20px;
  }
  @media (max-width: 600px) {
    width: 100%;
    align-items: left;
    margin-bottom: 20px;
  }
  //   &:first-child {
  //     @media (min-width: 1001px) {
  //       margin-bottom: 30px;
  //     }
  //   }
`;

export const FooterTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  color: white;
  line-height: 30px;
  text-transform: capitalize;
  margin-bottom: 28px;
  letter-spacing: 0.02em;
  position: relative;

  &::before,
  &::after {
    content: "";
    display: block;
    height: 2px;
    background-color: black;
    position: absolute;
    bottom: -15px;
  }

  &::before {
    width: 6px;
    left: 0;
    background-color: #cd7f32;
    opacity: 0.8;
  }

  &::after {
    width: 6rem;
    left: 0.6rem;
    background-color: #cd7f32;
    opacity: 0.8;
  }
`;

export const ReadMoreLink = styled(HashLink)`
  font-size: 16px;
  font-weight: 400;
  color: #4169e1;
  opacity: 0.7;
  &:hover {
    // text-decoration: none;
    opacity: 1;
  }
`;

export const FooterText = styled.div`
  //   text-align: justify;
  text-justify: inter-word;
  display: block;
  word-spacing: 1px;
  line-height: 140%;
  color: #faf9f6;
  /* opacity: 0.8; */
`;

export const FooterLink = styled(Link)`
  line-height: 24px;
  color: white;
  /* opacity: 0.8; */
  font-size: 14px;
  text-align: left;
  text-decoration: none;
  transition: color 0.3s ease, opacity 0.3s ease;
  &:hover {
    text-decoration: none;
    opacity: 0.7;
    color: #4169e1;
  }
  @media (max-width: 600px) {
    text-align: left;
    width: 90%;
  }
`;

export const FooterHashLink = styled(HashLink)`
  line-height: 24px;
  color: white;
  /* opacity: 0.8; */
  font-size: 14px;
  text-align: left;
  text-decoration: none;
  transition: color 0.3s ease, opacity 0.3s ease;
  &:hover {
    text-decoration: none;
    color: #4169e1;
    opacity: 0.7;
  }
  @media (max-width: 600px) {
    text-align: left;
    width: 90%;
  }
`;

export const LegalInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1.5rem 0;
  font-size: 14px;
  text-align: center;
  @media (max-width: 1000px) {
    flex-direction: row;
  }
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const LegalBlock = styled.div`
  padding: 3px 10px;
  color: white;
  @media (max-width: 600px) {
    padding: 3px 0;
  }
`;

export const FlexDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SocialIcon = styled.img`
  width: 30px;
  cursor: pointer;
`;
