import React, { useState, useRef } from 'react';
import styled from "styled-components";
import { ColorRing } from 'react-loader-spinner'
import { Grid, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { useInView } from "react-intersection-observer";
import emailjs from '@emailjs/browser';

// Styled components
const AppContainer = styled.div`
  display: flex;
  font-family: Arial, sans-serif;
`;

const Sidebar = styled.div`
  width: 400px; /* Set a default width */
  max-width: 90vw; /* Ensure popup fits on smaller screens */
  background-color: #fafafa;
  padding: 20px;
  border-radius: 10px;
  position: fixed;
  right: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  top: 50%;
  z-index: 99000;
  transition: transform 0.3s ease;
  transform: ${(props) => (props.isOpen ? "translateX(0)" : "translateX(100%)")};

  @media (max-width: 1000px) {
    top: 30%;
  }

`;

const ToggleButton = styled.button`
  position: absolute;
  top: 20px;
  left: -27px;
  font-size: 15px;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  padding: 10px 5px;
  background-color: #000080;
  color: white;
  cursor: pointer;
  border: none;
  outline: none;
  writing-mode: vertical-rl;
  transform: translateY(-50%);

  text-orientation: mixed;
  
  transform: rotate(180deg);
  cursor: pointer;
  z-index: 999000;
`;

const Overlay = styled.div`
position: fixed;
top: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
z-index: 1000;
display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

const CloseButton = styled.button`
position: absolute;
top: 10px;
right: 10px;
background: none;
border: none;
font-size: 40px;
cursor: pointer;
`;


const Heading = styled.h3`
font-weight: bold;
margin-bottom: 0px;
`

const ContentContainer = styled(Grid)`
display: flex;
`;

const InputFieldContainer = styled(Grid)`
padding-bottom: 15px;
`;

const InputField = styled(TextField)`

margin-bottom: 20px;
size: large;

.MuiOutlinedInput-root {
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #000080; // Color when focused
  }
}

.MuiInput-underline:after {
  border-bottom-color: #000080; // Change this to your desired color
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom-color: #000080; // Change this to your desired hover color
}
`;

const PopupBtn = styled.button`
display: flex;
align-items: center;
justify-content: center;
gap: 8px; /* Space between text and icon */
border-radius: 5px;
height: 40px;
width: 35%; /* Adjust the width as needed */
padding: 10px 0; /* Adjust padding to ensure the button height is consistent */
color: #fff;
font-size: 15px;
outline: none;
cursor: pointer;
transition: all 0.2s ease-in-out;
background-color: #000080;
border: none;

&:hover {
  background-color: #1A1AFF;
  color: #fff;
}
`;

const Content = styled.div`
  padding: 10px;
  z-index: 999000;
`;

function SidePannel() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const initialFormValues = {
    name: "",
    email: "",
    message: "",
    phone: "",
  };
  const form = useRef();
  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [showLoader, SetShowLoader] = useState(false);
  const [formValues, setFormValues] = useState(initialFormValues);

  const togglePopup = () => {
    console.log("clicked")
    setIsOpen(!isOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const sendEmail = (e) => {
    SetShowLoader(true)
    e.preventDefault();

    const templateParams = {
      to_name: formValues.name,
      phone: formValues.phone,
      email: formValues.email,
      message: formValues.message,
    };

    emailjs
      .send('service_2d9g5zz', 'template_ujotx3t', templateParams, 'jxjUxquGPo2WY_AU-')
      .then(
        () => {
          SetShowLoader(false)
          console.log('SUCCESS!');
          setIsOpen(false);
          setFormValues(initialFormValues);
          toast.success('Sucessfully Submitted!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        },
        (error) => {
          SetShowLoader(false)
          setIsOpen(false)
          console.log('FAILED...', error.text);
        }
      );
  };


  return (
    <>
      <Overlay isOpen={isOpen} onClick={toggleSidebar} />
      <AppContainer>
        <Sidebar isOpen={isOpen}>
        <CloseButton onClick={toggleSidebar}>&times;</CloseButton>
          <ToggleButton onClick={toggleSidebar}>
            {isOpen ? "Close" : "Get in touch"}
          </ToggleButton>
          <Heading>Get in touch with us</Heading>

          <form ref={form} onSubmit={sendEmail}>
          <Grid xs={12} md={12}>
            <ContentContainer item xs={12} md={12} >
              <InputFieldContainer item xs={5} md={5} >
                <InputField
                  variant="standard"
                  type="text"
                  name="name"
                  label="Name"
                  required
                  value={formValues.name}
                  onChange={handleChange}
                />
              </InputFieldContainer>
              <InputFieldContainer item xs={5} md={5} >
                <InputField
                  variant="standard"
                  type="text"
                  label="Phone (optional)"
                  name="phone"
                  value={formValues.phone}
                  onChange={handleChange}
                />
              </InputFieldContainer>
            </ContentContainer>

            <InputFieldContainer item xs={12} md={12} >
              <InputField
                variant="standard"
                fullWidth
                type="email"
                required
                label="Email Address"
                name="email"
                value={formValues.email}
                onChange={handleChange}
              />
            </InputFieldContainer>
            <InputFieldContainer item xs={12} md={12} >
              <InputField
                multiline rows={2}
                fullWidth
                required
                label="Message"
                name="message"
                value={formValues.message}
                onChange={handleChange}
              ></InputField>
            </InputFieldContainer>
            <Grid item xs={12} md={12} >
              <PopupBtn type="submit">Submit
                <ColorRing
                  visible={showLoader}
                  height="35"
                  width="35"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff']}
                /></PopupBtn>
            </Grid>
          </Grid>
        </form>
        </Sidebar>
      </AppContainer>
    </>
  );
}

export default SidePannel;
