import React from "react";
import PhotoGallery from "../components/home/PhotoGallery";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import Services from "../components/services/Services";
import AboutUsHome from "../components/aboutUsHome/AboutUsHome";
import PopupForm from "../components/popupForm/PopupForm";
import FAQs from "../components/home/FAQs";
import ContactUs from "../components/contactUs/ContactUs";
import SidePannel from "../components/home/SidePannel";
const Home = () => {
  return (
    <div>

      <Navbar />
      <PhotoGallery />
      <AboutUsHome />
      <SidePannel />
      <section id="services">
        <Services />
      </section>
      {/* <PopupForm /> */}
      <section id="faqs">
        <FAQs />
      </section>
      <section id="contactus">
        <ContactUs />
      </section>
      <Footer />
    </div>
  );
};

export default Home;
