import styled from "styled-components";
import { IconButton, Box, Grid } from "@mui/material";
import { HashLink } from "react-router-hash-link";

const breakpoint = "950px";

export const CarouselContainer = styled(Box)`
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: #fafafa;
  padding: 10px;
  scroll-behavior: smooth;
  position: relative;
  user-select: none; /* Prevents text selection while dragging */
  @media screen and (max-width: ${breakpoint}) {
    align-items: left;
    align-content: left;
    justify-content: left;
  }
  
`;

export const CarouselContent = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  transition: transform 0.5s ease-in-out;
 
`;

export const CarouselItem = styled.div`
  width: 100px;
  height: 120px;
  text-align: center;
  padding: 5px;
  border: 1px solid none;
  border-radius: 10px;
  transition: transform 0.2s ease, background-color 0.2s ease,
    border-radius 0.2s ease, box-shadow 0.2s ease, opacity 0.3s ease;
  cursor: pointer;
  margin: 0px 10px;
  user-select: none;

  &:hover {
    transform: scale(1.05);
    background: #f0c381;
    /* color: #000080; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    opacity: 0.9;
  }

  &.active {
    transform: scale(0.95);
    background: #e2a251;
    color: #000080;
    opacity: 0.9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;

export const ItemLabel = styled.div`
  display: block;
  font-size: small;
  color: inherit;
`;

export const ServicesContainer = styled(Box)`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: #fafafa;
  padding: 1rem 6%;
  position: relative;
  overflow: hidden;
  user-select: none;

  // @media (max-width: 1200px) {
  //   padding: 2rem 8%;
  // }

  @media (max-width: 1000px) {
    padding: 2rem 6%;
  }

  @media (max-width: 600px) {
    padding: 1.5rem 3%;
  }
`;

export const Heading = styled.div`
  font-size: xx-large;
  font-weight: bold;
  margin-bottom: 20px;
  /* color: #000080; */

  @media screen and (max-width: ${breakpoint}) {
    font-size: x-large;
    display: flex;
    justify-content: center;
  }
`;

export const SubHeading = styled.div`
  font-size: large;
  font-weight: bold;
  margin-bottom: 20px;

  @media screen and (max-width: ${breakpoint}) {
    font-size: medium;
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
  }
`;

export const Content = styled.div`
  font-size: large;
  margin-bottom: 20px;
  color: #444444;

  @media screen and (max-width: ${breakpoint}) {
    display: flex;
    font-size: medium;
    justify-content: center;
    text-align: justify;
    text-justify: inter-word;
  }
`;

export const Image = styled.img`
  width: 500px;
  height: 400px;
  border-radius: 15px;

  @media screen and (max-width: ${breakpoint}) {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
  }
`;

export const ContentContainer = styled(Grid)`
  align-items: center;
  align-content: center;
  justify-content: center;
`;

export const ContentInfo = styled(Grid)`
  align-items: center;
  align-content: center;
  justify-content: center;
  align-self: center;
`;

export const ServicesHeading = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: #fafafa;
  padding: 40px 0px;
`;

export const ServicesHeading1 = styled(Box)`
width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-size: xx-large;
  font-weight: bold;
  color: #000080;


  @media screen and (max-width: ${breakpoint}) {
    font-size: large;
  }
`;

export const ServicesSubHeading = styled(Box)`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-size: large;
  margin-bottom: 25px;

  @media screen and (max-width: ${breakpoint}) {
    font-size: medium;
  }
`;
