import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ColorRing } from "react-loader-spinner";
import { Grid, CardContent, Card } from "@mui/material";
import { SvgIcon } from "@mui/material";
import emailjs from "@emailjs/browser";
import {
  Heading,
  Heading1,
  ContactContainer,
  ContactGrid,
  ButtonContainer,
  GridItem,
  ContactSubHeading,
  ContactSubHeading2,
  ContactSubHeading3,
  MailIcon,
  PhoneIcon,
  PhoneIcon2,
  PopupBtn,
  InputFieldContainer,
  InputField,
} from "./style";
import { useInView } from "react-intersection-observer";

const ContactUs = () => {
  const initialFormValues = {
    name: "",
    email: "",
    message: "",
    phone: "",
  };

  const [formValues, setFormValues] = useState(initialFormValues);
  const [showLoader, SetShowLoader] = useState(false);

  const form = useRef();

  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.2 });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const sendEmail = (e) => {
    SetShowLoader(true);
    e.preventDefault();

    const templateParams = {
      to_name: formValues.name,
      phone: formValues.phone,
      email: formValues.email,
      message: formValues.message,
    };

    emailjs
      .send(
        "service_2d9g5zz",
        "template_ujotx3t",
        templateParams,
        "jxjUxquGPo2WY_AU-"
      )
      .then(
        () => {
          SetShowLoader(false);
          console.log("SUCCESS!");
          setFormValues(initialFormValues);
          toast.success("Sucessfully Submitted!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        },
        (error) => {
          SetShowLoader(false);
          console.log("FAILED...", error.text);
        }
      );
  };

  const handleReset = (e) => {
    e.preventDefault();
    setFormValues(initialFormValues); // Resetting formValues to initial state
  };

  return (
    <>
      <Heading>
        <Heading1>Get in touch with us</Heading1>
      </Heading>
      <ContactContainer>
        <Grid container spacing={3}>
          <ContactGrid item xs={12} md={6}>
            <GridItem item xs={12} md={12}></GridItem>
            <GridItem item xs={12} md={12}>
              <ContactSubHeading>
                Email or call us, We'd love to hear from you
              </ContactSubHeading>
            </GridItem>
            <GridItem item xs={12} md={12} ref={ref1} animate={inView1}>
              <ContactSubHeading2>
                <SvgIcon component={MailIcon} style={{ fontSize: "2rem" }} />
                info@simplifylegalops.com
              </ContactSubHeading2>
            </GridItem>
            <GridItem item xs={12} md={12} ref={ref1} animate={inView1}>
              <ContactSubHeading2>
                <SvgIcon component={PhoneIcon} style={{ fontSize: "2rem" }} />
                14210 Edinburgh Ct,
              </ContactSubHeading2>
            </GridItem>
            <GridItem item xs={12} md={12} ref={ref1} animate={inView1}>
              <SvgIcon component={PhoneIcon2} style={{ fontSize: "2rem" }} />
              Houston,TX, 77077
            </GridItem>
          </ContactGrid>
          <Grid item xs={12} md={6}>
            <form ref={form} onSubmit={sendEmail}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Grid xs={12} md={12}>
                    <ContactSubHeading3>
                      Fill out the form below and our representative will be in
                      touch
                    </ContactSubHeading3>
                    <InputFieldContainer item xs={12} md={12}>
                      <InputField
                        variant="outlined"
                        fullWidth
                        type="text"
                        name="name"
                        label="Name"
                        required
                        value={formValues.name}
                        onChange={handleChange}
                      />
                    </InputFieldContainer>
                    <InputFieldContainer item xs={12} md={12}>
                      <InputField
                        variant="outlined"
                        fullWidth
                        type="text"
                        label="Phone (optional)"
                        name="phone"
                        value={formValues.phone}
                        onChange={handleChange}
                      />
                    </InputFieldContainer>

                    <InputFieldContainer item xs={12} md={12}>
                      <InputField
                        variant="outlined"
                        fullWidth
                        type="email"
                        required
                        label="Email Address"
                        name="email"
                        value={formValues.email}
                        onChange={handleChange}
                      />
                    </InputFieldContainer>
                    <InputFieldContainer item xs={12} md={12}>
                      <InputField
                        multiline
                        rows={3}
                        fullWidth
                        required
                        label="Message"
                        name="message"
                        value={formValues.message}
                        onChange={handleChange}
                      ></InputField>
                    </InputFieldContainer>
                    <Grid item xs={12}></Grid>
                  </Grid>
                </CardContent>
                <ButtonContainer>
                  <PopupBtn onClick={handleReset} className="reset">
                    Reset
                  </PopupBtn>
                  <PopupBtn type="submit">
                    Submit
                    <ColorRing
                      visible={showLoader}
                      height="35"
                      width="35"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={[
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                      ]}
                    />
                  </PopupBtn>
                </ButtonContainer>
              </Card>
            </form>
          </Grid>
        </Grid>
      </ContactContainer>
    </>
  );
};

export default ContactUs;
