import styled, { css, keyframes } from "styled-components";
import { HashLink } from 'react-router-hash-link';

const fadeInUp = keyframes`
  from {
    opacity: 0;
   transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideInRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(100px); 
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const HeaderContainer = styled.div`
  background-color: #f0c381;
  border-radius: 10px;
  padding: 80px;
  text-align: center;
  width: 100%;
  margin-bottom: 2rem;
`;

export const StyledContainer = styled.div`
  background-color: white;
  color: black;
  padding: 4rem 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Header = styled.h1`
  color: #000080;
  margin-bottom: 0.5rem;
  animation: ${fadeInUp} 1s ease-in-out forwards;
`;

export const SubHeader = styled.h2`
  color: black;
  margin: 0;
  opacity: 0.9;
  animation: ${fadeInUp} 1s ease-in-out forwards;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  justify-content: space-between;
  align-items: flex-start;
  margin: 2rem 0;
  gap: 40px;
  @media (max-width: 960px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const ImageBox = styled.div`
  flex: 1;
  img {
    width: 100%;
    height: 350px;
    display: block;

    @media (max-width: 800px) {
      height: 300px;
    }
  }
`;

// export const DecorativeShape = styled.div`
//   flex: 1;
//   width: 100%;
//   height: auto;
//   background-color: #a41f1e;
//   clip-path: polygon(50% 0%, 100% 50%, 80% 100%, 0% 50%);
//   border-radius: 50px;
// `;

export const TextContent = styled.div`
  flex: 1;
  // padding: ${({ right }) => (right ? "0 50px 0 0" : "0 0 0 50px")};
  // @media (max-width: 960px) { padding: ${({ right }) =>
    right ? "0" : "0"}; }
`;

export const Text = styled.p`
  color: #444444;

  ${({ animate }) =>
    animate &&
    css`
      animation: ${fadeInUp} 1s ease-in-out forwards;
    `}
`;

export const SectionHeader = styled.h3`
  color: #000080;
  margin-bottom: 10px;
`;

export const StyledButton = styled(HashLink)`
border-radius: 4px;
background:  #000080;
padding: 10px 22px;
color: #fff;
outline: none;
cursor: pointer;
transition: all 0.2s ease-in-out;
text-decoration: none;

&:hover {
  transition: all 0.2s ease-in-out;
  background: black;
  color: #fff;
}
`;

export const Feature = styled.div`
  margin-top: 1rem;
  background-color: #f8f8f8; // Light gray for slight emphasis
  padding: 20px;
  border-left: 5px solid  #CD7F32; // Red line for visual attention
`;

export const FeatureHeader = styled.h4`
  color: #CD7F32;
  margin-bottom: 5px;
`;

export const FeatureText = styled.p`
  color: #444444;
  padding: 10px 0;

  ${({ animate }) =>
    animate &&
    css`
      animation: ${slideInRight} 1s ease-out forwards;
    `}
`;
